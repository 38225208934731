<template>
    <div>
        <div class="col-12 row">
            <div class="col-5 mt-2  mb-2 row">
                <div class="row" v-if="auctions && auctions.length > 0">
                    <img :src="auctions[0].image" style="width: 135px;height: 100px;" />
                    <div style="width: calc(100% - 135px)" class="mt-4">
                        <span style="font-weight: bold;">Auction Open Price&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;: {{
                            auctions[0].open_price }}</span>
                        <span :class="auctions[0].is_up_live_percentage
                            ? ' greenColor'
                            : ' redText'
                            " style="font-weight: bold; font-size: 25px;margin-left: 4%;position:absolute">
                            {{ auctions[0].live_percentage }}%
                            <i :class="auctions[0].is_up_live_percentage
                                ? 'bi-arrow-up greenColor'
                                : 'bi-arrow-down redText'
                                " aria-hidden="true"></i>
                        </span>
                        <br />
                        <span style="font-weight: bold;">
                            Auction Declare Price : {{ auctions[0].price }}</span>

                    </div>
                </div>
            </div>
            <div class="col-2 text-center mt-4" style="position: relative;">
                <img style="width: 50%;" src="/img/logo.jpg">
            </div>
            <div class="col-5 mt-2 text-end">
                <div class="row" v-if="auctions && auctions.length > 1">
                    <div  style="width: calc(100% - 135px)" class="mt-4">
                        <span :class="auctions[1].is_up_live_percentage
                            ? ' greenColor'
                            : ' redText'
                            " style="font-weight: bold; font-size: 25px;margin-left: -8%;position:absolute">
                            {{ auctions[1].live_percentage }}%
                            <i :class="auctions[1].is_up_live_percentage
                                ? 'bi-arrow-up greenColor'
                                : 'bi-arrow-down redText'
                                " aria-hidden="true"></i>
                        </span>

                        <span style="font-weight: bold;">{{
                            auctions[1].open_price }}:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Auction Open Price </span>
                        <br />
                        <span style="font-weight: bold;">{{ auctions[1].price }}:
                            Auction Declare Price </span>
                    </div>
                    <img :src="auctions[1].image"  style="width: 135px;height: 100px;" />
                </div>
            </div>
        </div>
        <div class="row col-12 mb-2 tv-div">
            <div class="col-md-4">
                <span class="price">Auction Start Time - {{ currentTime }}</span>
            </div>
            <div class="col-md-4 text-center">
                <span class="price">Time Left for Auction Declare - {{ timerCount }}</span>
            </div>
            <div class="col-md-4 text-end">
                <span class="price">Auction End Time - {{ endTime }}</span>
            </div>

        </div>
        <div class="row">
            <Line class="col-3" v-if="silverLoading" :chart-data="silverChart" height="200" :chart-options="options" />
            <Bar class="col-3" height="200" v-if="silverLoading" :chart-data="silverBarChart" :chart-options="barOptions" />
            <Line class="col-3" v-if="goldLoading" :chart-data="goldChart" height="200" :chart-options="options" />
            <Bar class="col-3" height="200" v-if="goldLoading" :chart-data="goldBarChart" :chart-options="barOptions" />
        </div>


        <div class="row">
            <div id="table1" class="col-md-6 bg-light" style="height: 62vh; overflow: scroll;">
                <table id="table" data-toggle="table" data-search="true" data-filter-control="true" data-toolbar="#toolbar"
                    class="
              table table-sm
              text-center text-white
              table-bordered
              product-list
              table-hover
            ">
                    <thead>
                        <tr>
                            <th>Auction Time</th>
                            <th class="head">Open Price</th>
                            <th class="head">Declear Price</th>
                            <th class="head">Declear %</th>
                            <th class="head">Open Price</th>
                            <th class="head">Declear Price</th>
                            <th class="head">Declear %</th>
                            <th class="head">Open Price</th>
                            <th class="head">Declear Price</th>
                            <th class="head">Declear %</th>
                        </tr>
                    </thead>
                    <tbody v-if="!loader && auctions.length > 0">
                        <tr v-for="(data, index) in list" :key="index">
                            <td class="body">{{ data.date }}</td>
                            <td class="body">{{ auctions[0].open_price }}</td>
                            <td class="body"><div  :class="data.u5 ? 'green' : 'red'">{{ getPrice(data.u5, data.p5, auctions[0].open_price) }}</div></td>
                            <td class="body">
                                <div class="percentageText" style="font-size:12px"
                                    :class="data.u5 ? 'green' : 'red'">
                                    {{ data.p5 }}%
                                    <i :class="data.u5
                                        ? 'bi-arrow-up'
                                        : 'bi-arrow-down'
                                        " aria-hidden="true"></i>
                                </div>
                            </td>
                            <td class="body">{{ auctions[0].open_price }}</td>
                            <td class="body"><div  :class="data.u6 ? 'green' : 'red'">{{ getPrice(data.u6, data.p6, auctions[0].open_price) }}</div></td>
                            <td class="body">
                                <div class="percentageText" style="font-size:12px"
                                    :class="data.u6 ? 'green' : 'red'">
                                    {{ data.p6 }}%
                                    <i :class="data.u6
                                        ? 'bi-arrow-up'
                                        : 'bi-arrow-down'
                                        " aria-hidden="true"></i>
                                </div>
                            </td>
                            <td class="body">{{ data.p7 ? auctions[0].open_price : '' }}</td>
                            <td class="body"><div  :class="data.u7 ? 'green' : 'red'">{{ getPrice(data.u7, data.p7, auctions[0].open_price) }}</div></td>
                            <td class="body">
                                <div v-show="data.p7" class="percentageText" style="font-size:12px"
                                    :class="data.u7 ? 'green' : 'red'">
                                    {{ data.p7 }}%
                                    <i :class="data.u7
                                        ? 'bi-arrow-up'
                                        : 'bi-arrow-down'
                                        " aria-hidden="true"></i>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div id="table2" class="col-md-6 bg-light" style="height: 62vh; overflow: scroll;">
                <table id="table" data-toggle="table" data-search="true" data-filter-control="true" data-toolbar="#toolbar"
                    class="
              table table-sm
              text-center text-white
              table-bordered
              product-list
              table-hover
            ">
                    <thead>
                        <tr>
                            <th>Auction Time</th>
                            <th class="head">Open Price</th>
                            <th class="head">Declear Price</th>
                            <th class="head">Declear %</th>
                            <th class="head">Open Price</th>
                            <th class="head">Declear Price</th>
                            <th class="head">Declear %</th>
                            <th class="head">Open Price</th>
                            <th class="head">Declear Price</th>
                            <th class="head">Declear %</th>
                        </tr>
                    </thead>
                    <tbody v-if="!loader1 && auctions.length > 0">
                        <tr v-for="(data, index) in list1" :key="index">
                            <td class="body" >{{ data.date }}</td>
                            <td class="body">{{ auctions[1].open_price }}</td>
                            <td class="body"><div  :class="data.u5 ? 'green' : 'red'">{{ getPrice(data.u5, data.p5, auctions[1].open_price) }}</div></td>
                            <td class="body">
                                <div class="percentageText" style="font-size:12px"
                                    :class="data.u5 ? 'green' : 'red'">
                                    {{ data.p5 }}%
                                    <i :class="data.u5
                                        ? 'bi-arrow-up'
                                        : 'bi-arrow-down'
                                        " aria-hidden="true"></i>
                                </div>
                            </td>
                            <td class="body">{{ auctions[1].open_price }}</td>
                            <td class="body"><div  :class="data.u6 ? 'green' : 'red'">{{ getPrice(data.u6, data.p6, auctions[1].open_price) }}</div></td>
                            <td class="body">
                                <div class="percentageText" style="font-size:12px"
                                    :class="data.u6 ? 'green' : 'red'">
                                    {{ data.p6 }}%
                                    <i :class="data.u6
                                        ? 'bi-arrow-up'
                                        : 'bi-arrow-down'
                                        " aria-hidden="true"></i>
                                </div>
                            </td>
                             <td class="body">{{ data.p7 ? auctions[1].open_price : '' }}</td>
                            <td class="body"><div  :class="data.u7 ? 'green' : 'red'">{{ getPrice(data.u7, data.p7, auctions[1].open_price) }}</div></td>
                            <td class="body">
                                <div v-show="data.p7" class="percentageText" style="font-size:12px"
                                    :class="data.u7 ? 'green' : 'red'">
                                    {{ data.p7 }}%
                                    <i :class="data.u7
                                        ? 'bi-arrow-up'
                                        : 'bi-arrow-down'
                                        " aria-hidden="true"></i>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <canvas id="line-chart" v-show="false"></canvas>
    </div>
</template>
<script>
import moment from "moment";
import { Line, Bar } from "vue-chartjs";
import webstomp from 'webstomp-client';
import {
    Chart as ChartJS,
    LineElement,
    LinearScale,
    BarElement,
    PointElement,
    CategoryScale,
    ArcElement,
    Legend
} from 'chart.js'

ChartJS.register(
    LineElement,
    BarElement,
    LinearScale,
    PointElement,
    CategoryScale,
    ArcElement,
    Legend
)

export default {
    components: {
        Line,
        Bar
    },
    name: "TvProduct",
    computed: {
        timerCount() {
            var minutes = Math.floor(this.time / 60);
            return (
                this.numberFormat(minutes) +
                ":" +
                this.numberFormat(this.time - minutes * 60)
            );
        },
    },
    mounted() {
        const ctx = (
            document.getElementById('line-chart')
        ).getContext('2d');
        const gradientFill = ctx.createLinearGradient(0, 500, 0, 200);
        gradientFill.addColorStop(1, 'rgb(19, 170, 82)');
        gradientFill.addColorStop(0, 'rgb(0, 102, 43)');
        this.greenGradient = gradientFill;


        const gradientFillRed = ctx.createLinearGradient(0, 500, 0, 200);
        gradientFillRed.addColorStop(1, 'rgb(238, 56, 56,1)');
        gradientFillRed.addColorStop(0, 'rgb(206, 21, 0, 0.92)');
        this.redGradient = gradientFillRed;

        for (var i = 0; i < 30; i++) {
            this.silverChart.labels.push('');
            this.goldChart.labels.push('');
        }

        this.getTime();
        var self = this;
        setInterval(() => {
            if (self.time > 0) {
                self.time--;
            } else {
                self.getTime();
            }
        }, 1000);

        this.connect();
    },
    data() {
        return {
            greenGradient: '',
            redGradient: '',
            list: [],
            list1: [],
            loader: true,
            loader1: true,
            silverLoading: false,
            goldLoading: false,
            version: 1,
            options: {
                bezierCurve: false,
                responsive: true,
                plugins: {
                    legend: {
                        display: false
                    }
                },
                animation: {
                    duration: 0
                },
                elements: {
                    point: {
                        radius: 0
                    }
                },
                scales: {
                    y: {
                        max: 10,
                        min: 0,
                        ticks: {
                            stepSize: 2
                        }
                    },
                    x: {
                        display: false
                    }
                }
            },
            goldChart: {
                labels: [],
                datasets: [{
                    borderWidth: 3,
                    backgroundColor: 'green',
                    lineTension: 0.5,
                    borderColor: 'green', data: []
                }, {
                    borderWidth: 2.5,
                    backgroundColor: 'red',
                    lineTension: 0.5,
                    borderColor: 'red', data: []
                }]
            },
            silverChart: {
                labels: [],
                datasets: [{
                    borderWidth: 3,
                    backgroundColor: 'green',
                    lineTension: 0.5,
                    borderColor: 'green', data: []
                }, {
                    borderWidth: 2.5,
                    backgroundColor: 'red',
                    lineTension: 0.5,
                    borderColor: 'red', data: []
                }]
            },
            silverBarChart: {
                labels: [],
                datasets: [
                    {
                        label: 'Up',
                        backgroundColor: [],
                        data: [],
                        borderColor: "rgba(0,0,0,0)",
                        borderWidth: 5,
                        barThickness: 34,
                        maxBarThickness: 36
                    },

                ],
            },
            goldBarChart: {
                labels: [],
                datasets: [
                    {
                        label: 'Up',
                        backgroundColor: [],
                        data: [],
                        borderColor: "rgba(0,0,0,0)",
                        borderWidth: 5,
                        barThickness: 34,
                        maxBarThickness: 36
                    },

                ],
            },
            barOptions: {
                maintainAspectRatio: false,
                plugins: {
                    legend: {
                        display: false
                    }
                },
                responsive: true,
                scales: {
                    y:
                    {
                        suggestedMax: 10,
                        suggestedMin: 0,
                        grid: {
                            borderColor: 'rgba(0,0,0,0)',
                            color: function (context) {
                                if (context.tick.value == 0) {
                                    return 'rgba(0,0,0,0)';
                                } else {
                                    return 'rgba(29, 140, 248, 0.1)';
                                }

                            },
                        }
                    },
                    x: {
                        grid: {
                            borderColor: 'rgba(0,0,0,0)',
                            display: false
                        }
                    }
                },
            },
            goldData: [],
            goldDataU: [],
            auctions: [],
            silverData: [],
            silverDataU: [],
            time: 0,
            isCall: false,
            count: 0,
            endTime: '',
            currentTime: ''
        };
    },
    methods: {

        getPrice(u, p, price) {
            return p ? (u ? price + (price *
                p / 100) : price - (price * p / 100)).toFixed(2) : ''
        },
        connect() {
            var login = 'admin';
            var password = 'admin@3792';
            var $this = this;
            var client = webstomp.client(this.$api.getSocketUrl(), { debug: false });
            client.connect(login, password, () => {
                client.subscribe("/exchange/plot-data", (message) => {
                    $this.parseData(message.body)
                }
                );
            });
        },
        parseData(data) {
            var obj = JSON.parse(data);
            this.updateGraphData(obj.su, obj.sd, obj.gu, obj.gd)

        },
        updateGraphData(su, sd, gu, gd) {
            if (this.silverDataU.length > 30) {
                this.silverDataU.splice(0, 25);
            }

            this.silverDataU.push(su);
            this.silverChart.datasets[0].data = this.silverDataU;

            if (this.silverData.length > 30) {
                this.silverData.splice(0, 25);
            }

            this.silverData.push(sd);
            this.silverChart.datasets[1].data = this.silverData;



            if (this.goldDataU.length > 30) {
                this.goldDataU.splice(0, 25);
            }

            this.goldDataU.push(gu);
            this.goldChart.datasets[0].data = this.goldDataU;

            if (this.goldData.length > 30) {
                this.goldData.splice(0, 25);
            }

            this.goldData.push(gd);
            this.goldChart.datasets[1].data = this.goldData;
        },
        getCurrentAuction() {
            this.$api
                .getAPI({
                    _action: "/auctions/web/live",
                })
                .then((res) => {
                    for (var i in res) {
                        res[i].is_checked = false;
                    }

                    this.auctions = res;
                    if (res.length > 0) {
                        this.currentTime = moment(res[0].start_time).format(
                            "hh:mm a"
                        );
                        this.endTime = moment(res[0].start_time).add(15, 'm').format(
                            "hh:mm a"
                        );
                    }
                })
                .catch(() => { });
        },
        numberFormat(n) {
            return n > 9 ? "" + n : "0" + n;
        },
        getTime() {
            if (!this.isCall) {
                this.isCall = true;
                var $this = this;
                if (this.count > 0) {
                    setTimeout(() => {
                        $this.apiCall(true);
                    }, this.count * 1000);
                } else {
                    this.apiCall(false);
                }
            }
        },
        apiCall(flag) {
            this.count++;
            this.$api
                .getAPI({
                    _action: "/auctions/time",
                    _is_hide_error: true
                })
                .then((res) => {
                    res.version = 2;
                    if (res.version && this.version != res.version) {
                        if (flag) {
                            location.reload();
                            return;
                        }
                    }

                    this.version = res.version;
                    this.time = res.time;
                    this.count = 0;
                    this.isCall = false;
                    this.getCurrentAuction();
                    this.getList();
                    this.getList1();
                })
                .catch(() => {
                    this.isCall = false;
                });
        },
        getList() {
            this.loader = true;
            this.$api
                .getAPI({
                    _action: "/products/chart/1",
                    _is_hide_error: true
                })
                .then((res) => {
                    this.list = res;
                    this.loader = false;
                    var count = 0;
                    var index = -1;
                    for (var i in this.list) {
                        if (this.list[i].p7) {
                            count++;
                        } else if (index == -1) {
                            index = i - 1;
                        }
                    }

                    if (count <= 10) {
                        count = 0;
                    }

                    var data = [];
                    var label = [];
                    var color = [];
                    var isToday = true;
                    for (var j = 0; j < 6; j++) {
                        if (index < 0) {
                            index = 95;
                            isToday = false;
                        }

                        label.push(this.list[index].date)
                        if (isToday) {
                            if (this.list[index].u7) {
                                color.push(this.greenGradient);
                            } else {
                                color.push(this.redGradient);
                            } data
                            data.push(this.list[index].p7)
                        } else {
                            if (this.list[index].u6) {
                                color.push(this.greenGradient);
                            } else {
                                color.push(this.redGradient);
                            }

                            data.push(this.list[index].p6)
                        }

                        index--;
                    }

                    this.silverBarChart.labels = label.reverse();
                    this.silverBarChart.datasets[0].backgroundColor = color.reverse();
                    this.silverBarChart.datasets[0].data = data.reverse();
                    this.silverLoading = true;
                    setTimeout(() => {
                        document.getElementById("table1").scrollTo(0, count * 25);
                    }, 100);
                })
                .catch(() => {
                });
        },
        getList1() {
            this.loader1 = true;
            this.$api
                .getAPI({
                    _action: "/products/chart/2",
                    _is_hide_error: true
                })
                .then((res) => {
                    this.list1 = res;
                    this.loader1 = false;
                    var count = 0;
                    var index = -1;
                    for (var i in this.list1) {
                        if (this.list1[i].p7) {
                            count++;
                        } else if (index == -1) {
                            index = i - 1;
                        }
                    }


                    if (count <= 10) {
                        count = 0;
                    }


                    var data = [];
                    var label = [];
                    var color = [];
                    var isToday = true;
                    for (var j = 0; j < 6; j++) {
                        if (index < 0) {
                            index = 95;
                            isToday = false;
                        }

                        label.push(this.list1[index].date)
                        if (isToday) {
                            if (this.list1[index].u7) {
                                color.push(this.greenGradient);
                            } else {
                                color.push(this.redGradient);
                            } data
                            data.push(this.list1[index].p7)
                        } else {
                            if (this.list1[index].u6) {
                                color.push(this.greenGradient);
                            } else {
                                color.push(this.redGradient);
                            }

                            data.push(this.list1[index].p6)
                        }

                        index--;
                    }

                    this.goldBarChart.labels = label.reverse();
                    this.goldBarChart.datasets[0].backgroundColor = color.reverse();
                    this.goldBarChart.datasets[0].data = data.reverse();
                    this.goldLoading = true;

                    setTimeout(() => {
                        document.getElementById("table2").scrollTo(0, count * 25);
                    }, 100);
                })
                .catch(() => {

                });
        }
    }
};
</script>
  