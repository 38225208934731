<template>
  <router-view></router-view>
  <notifications position="top right" classes="vue-notification mt-3" />
</template>

<script>
export default {
  name: "App",
  components: {},
};
</script>
