<template><!-- ======= Header ======= -->
  <header id="header" class="fixed-top ">
    <div class="container-fluid">

      <div class="row justify-content-center">
        <div class="col-xl-12 d-flex align-items-center justify-content-lg-between">
          <h5 class="logo me-auto me-lg-0" style="color: white !important;"><a href="index.html">
              <img src="/img/logo.jpg">
            </a>
            <span>
              &nbsp;SDS AGRICULTURAL MANDDI PVT. LTD.</span>
          </h5>
 

          <nav id="navbar" class="navbar order-last order-lg-0">
            <ul>
              <li><a class="nav-link scrollto active" href="#home">Home</a></li>
              <li><a class="nav-link scrollto" href="#about">About Us</a></li>
              <li><a class="nav-link scrollto" href="#product">Products</a></li>
              <li><a class="nav-link scrollto" href="#services">Services</a></li>
              <li><a class="nav-link scrollto" href="#team">Team</a></li>
              <li><a class="nav-link scrollto" href="#contact">Contact</a></li>
            </ul>
            <i class="bi bi-list mobile-nav-toggle"></i>
          </nav><!-- .navbar -->

          <a v-click-outside="forceClose" v-on:click="showLogin"  class="get-started-btn scrollto">Login
            <ul v-show="isLogin" class="login-ul">
              <li v-on:click="redirect('http://dealer.sdstrading.live')" style="cursor: pointer">Dealer Portal</li>
              <li v-on:click="redirect('http://admin.sdstrading.live')" style="margin-top: 3px;cursor: pointer;">
                Admin Portal</li>
            </ul>
          </a>

        </div>
      </div>

    </div>
  </header><!-- End Header -->

  <!-- ======= home Section ======= -->
  <section id="hero" class="d-flex flex-column justify-content-center">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-xl-12">
          <div class="row" style="margin-bottom:50px">
            <div class="col-lg-6"><span style="color:white;font-size:20px; font-weight:bold;float:left">Auction Date :
                {{ currentDate }}</span></div>
            <div class="col-lg-6"><span style="color:white;font-size:20px; font-weight:bold;float:right">Auction Declare
                Time : {{ currentTime }}</span></div>
          </div>
          <div class="row">
            <div class="col-lg-3" v-for="(data, index) in auctions" :key="index">
              <img :src="data.image" width="160" />
              <br />
              <span i v-if="data.is_up_live_percentage" style="color:#07b600;font-size:20px; font-weight:bold">₹{{
                data.price
              }} {{ data.live_percentage }}% <i class="bi-arrow-up-short" style="font-size:22px;"></i></span>
              <span i v-if="!data.is_up_live_percentage" style="color:red;font-size:20px; font-weight:bold">₹{{
                data.price
              }} {{ data.live_percentage }}% <i class="bi-arrow-down-short" style="font-size:22px;"></i></span>
              <hr style="border-top:5px solid #00a9ff; width:60%;margin:auto" />
              <span style="color:white;font-size:20px; font-weight:bold">Open Price ₹{{ data.open_price }}</span>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-7 container">
              <div class="centered" style="background: #99D9EA;padding: 20px;border-radius: 10px;margin-top: 15%;">
                <span style="font-size:18px;color:blue;font-weight:bold">Next Declare Auction : {{
                  previousTime
                }}</span>
                <br />
                <span style="font-size:24px;color:red;font-weight:bold">Remaining Time : {{ timerCount }}</span>
              </div>
            </div>
          </div>
          <!--<h1>SDS- SOme dummy text here</h1>
        <h2>We are team of designers making websites with Bootstrap</h2>-->
        </div>
      </div>
    </div>
  </section><!-- End home -->

  <main id="main">

    <!-- ======= About Us Section ======= -->
    <section id="about" class="about">
      <div class="container">

        <div class="section-title">
          <h2>About Us</h2>
          <p>SDS is three fold Business Company which provides an
            opportunity to auction, to trade and to give
            brokerage. It is registered as Private Limited Company in the year 2019.</p>
        </div>

        <div class="row content">
          <div class="col-lg-6">
            <p>
              SDS Agricultural Manddi Pvt. Ltd. Is established in the month of January 20,2023 at
              Baramati. The real object of the Company is that to
              conduct the e-auction of Basmati Rice, to trade and
              broking for providing the online platform for holding the
              e-auction of the Basmati Rice. </p>
            <p>
              &nbsp; &nbsp;&nbsp;&nbsp;&nbsp;The primary object of the Company, to render the
              quality services to the public at large for providing the
              quality rice to all such persons who cannot afford the
              Basmati Rice for such public with minimum price rate and to provide the new and dynamic platform to the
              rice
              holder,
            </p>
          </div>
          <div class="col-lg-6 pt-4 pt-lg-0">
            <p>agriculturist or rice mill holder those who wants
              to bring agricultural products/ cereals in the e-auction
              platform the Company by accepting minimum brokerage
              provides not only the platform but also permits to bid in
              the online e-auction.</p>
            <p>
              &nbsp; &nbsp;&nbsp;&nbsp;&nbsp;
              Therefore it is one sort of greatest
              opportunity not only to the agriculturist but also for the
              consumers to purchase or to earn the profit/commission
              over the bidding rice by selling it to the company if they
              wanted to do so with margin profit/commission of Rs.
              1.82/- per KG over bidding amount is the commitment of
              the Company.

            </p>
            <!-- <a href="#" class="btn-learn-more">Learn More</a> -->
          </div>
        </div>
        <h4>Role of Company in E-Tender / E-Autcion</h4>
        <div class="row content">
          <div class="col-lg-6">
            <p>
              To provide platform for conducting e-auction of Basmati Rice. For conducting the
              e-auction the company is having their registered official website
              https://dealer.sdstrading.live.</p>
            <p>
              &nbsp; &nbsp;&nbsp;&nbsp;&nbsp;
              The company is acting as intermediatory in all the process of e-auction because
              the Agriculturalist / Rice Holder is having a good platform to sale the Rice at their
              own choice and having right to fix the price from their own product from home itself
              without going in to the market.
            </p>
          </div>
          <div class="col-lg-6 pt-4 pt-lg-0">
            <p>The Company is trying to reach to last person who is having chance to participate
              in the e-auction process by paying minimum brokerage and participation charges
              including deposits for immediate participation in the online e-auction without going
              in to the market. The bidder can participate by submitting his details and can
              purchase the qualitative rice at minimum price at any place in India. The Company
              is giving the delivery at the doorstep of the consumer. .</p>
            <p>
              &nbsp; &nbsp;&nbsp;&nbsp;&nbsp;
              The Company is accepting the brokerage for bidding participation and to enter
              at e-auction platform in such a lowest rate that anybody can participate and offer
              the bidding in the e-auction process.

            </p>
          </div>
        </div>

      </div>
    </section><!-- End About Us Section -->

    <section id="product" class="services">
      <div class="container">

        <div class="section-title">
          <h2>Products</h2>
        </div>


        <div class="row content">

          <div class="col-lg-7">

            <div class="content">
              <h3>Pusa <strong>Basmati 1121</strong></h3>
              <p>
                Pusa 1121 is an essentially derived variety evolved through the process of
                hybridization over a long breeding process. The variety of basmati rice was
                developed by Padma Shri awardee Devesh Mittal and his brother Aman Mittal.
                This variety of rice is known for its extraordinary kernel (grain) length, which
                can be as much as 8.4 millimeter (0.33 in) for a single grain, the longest-ever
                known released cultivar in the world. It has very high kernel elongation ratio
                ranging from 2 to 2.5, i.e. length of cooked rice kernel/length of uncooked
                kernel. When cooked, the rice does not turn sticky, possesses minimum
                breadth-wise expansion, and is aromatic with intermediate alkali-spreading
                value and amylose content.
              </p>
              <p>
                As of 2013 Pusa Basmati 1121 had spread over 84% of the total basmati area in
                Punjab, 78% in Western Uttar Pradesh, 68% in Haryana, 30% in Uttrakhand, 8%
                in Jammu and Kashmir and grown over a 1000-ha area in hill state of Himachal
                Pradesh. It is an important export commodity.[2] Its export has enhanced
                India's annual basmati foreign exchange earning from Rs 5000 crores in 2009 to
                Rs 27597.87 crores in 2014–15 (Source: DGCIS Annual Export). Pusa 1121 has
                major share of ~70%.
              </p>
              <p>
                Pusa Basmati 1121 Basmati rice available in 2 varieties so called white and
                golden Basmati rice. Most of rice supply in India is through from Punjab. Punjab
                is well known state of India for its agriculture products. Basmati rices are
                divided into two categories based on process of manufacturing, One is Sella and
                other is Steam. But Basmati Sella is most preferred rice because of high
                nutrition value.
              </p>
            </div>



          </div>

          <div class="col-lg-5">
            <img src="/img/faq.jpg" style="height: 600px;" />
          </div>
        </div>


        <div class="row mt-5">
          <div class="col-lg-3" v-for="(data, index) in auctions" :key="index">
            <img :src="data.image" width="160" />
            <br />
            <span style="color:red;font-size:17px; font-weight:bold"><s>MRP ₹{{ data.open_price + 1000 }}</s></span>
            <br />
            <span style="color:green;font-size:17px; font-weight:bold">Buy at ₹{{ data.open_price }}</span>
            <br />
            <span style="color:black;font-size:14px;">Shipping Extra</span>
            <br />
            <button
              style="padding: 5px 24px;margin-top: 6px;background: #121550;border: 0px;border-radius: 5px;color: white;"
              v-on:click="buy(data)">Buy</button>
          </div>
        </div>
      </div>
    </section>

    <!-- ======= Services Section ======= -->
    <section id="services" class="services">
      <div class="container">

        <div class="section-title">
          <h2>Services</h2>
          <p>SDS Agricultural Manddi Pvt. Ltd. is a house for single window solution to trader to Trade &
            Earn as well as buys the quality
            rice at very low cost and gets delivery immediate all over India.</p>
        </div>

        <div class="row">
          <div class="col-lg-3 col-md-6 d-flex align-items-stretch mt-4 mt-md-0">
            <div class="icon-box" style="width: 100%;">
              <!-- <div class="icon"><i class="bx bx-file"></i></div> -->
              <h4><a href="">To E-Tender /<br/> E-Auctioning</a></h4>
              <!-- <p>Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore</p> -->
            </div>
          </div>
          <div class="col-lg-3 col-md-6 d-flex align-items-stretch">
            <div class="icon-box" style="width: 100%;">
              <!-- <div class="icon"><i class="bx bxl-dribbble"></i></div> -->
              <h4><a href="">To Broking</a></h4>
              <!-- <p>Voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi</p> -->
            </div>
          </div>

          <div class="col-lg-3 col-md-6 d-flex align-items-stretch mt-4 mt-lg-0">
            <div class="icon-box" style="width: 100%;">
              <!-- <div class="icon"><i class="bx bx-tachometer"></i></div> -->
              <h4><a href="">Distribution</a></h4>
              <!-- <p>Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia</p> -->
            </div>
          </div>

          <div class="col-lg-3 col-md-6 d-flex align-items-stretch mt-4 mt-lg-0">
            <div class="icon-box" style="width: 100%;">
              <!-- <div class="icon"><i class="bx bx-tachometer"></i></div> -->
              <h4><a href="">To Trading</a></h4>
              <!-- <p>Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia</p> -->
            </div>
          </div>


          <div style="text-align: left; margin-top: 30px;">
            <div style="background: #009ab3; width: fit-content; border-radius: 5px; padding: 2px 10px;">
              <h4 style="width: fit-content;">To E-Tender/E-Auctioning</h4>
            </div>
          </div>

          <div class="row content" style="text-align: left;">
            <div class="col-lg-12">
              <p>
                To E-Tender/E-Auctioning is an process of actual auction which takes place by way of online
                auction. The auctioneer have rights to bring the cereals, agricultural products. The
                auction, all bidders who arrives at the auction platform for auctioning and bidding
                purposes the following information will be present in the platform.
              <ul>
                <li><i class="ri-check-double-line"></i> Opening Price</li>
                <li><i class="ri-check-double-line"></i> Opening Date and Time</li>
                <li><i class="ri-check-double-line"></i> Declare Price</li>
                <li><i class="ri-check-double-line"></i> Declare Date and Time</li>
                <li><i class="ri-check-double-line"></i> Product Name </li>
                <li><i class="ri-check-double-line"></i> Lot Size</li>
              </ul>
              The e-auction declare time is an automatic process which runs automatically
              after participation in the e-auction process. The bidders shall have choice to bid
              either at higher side of opening price fixed in the mode of percentage. Or the
              bidder shall also have choice to bid in reverse mode on the opening price in case
              higher side bid did not takes place.
              </p>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-8 order-2 order-lg-1">
              <p>The all auction process for participating in auction will be
                permitted only after successful online registration all intending
                bidders should fill the relevant information and shall pay the
                necessary brokerage by online or by cash at auction center. The non
                registered bidder will not be permitted. The registered bidder shall
                be provided the Bidder ID for participatio</p>
              <p>The SDS strongly advice the intending bidder not to test the e-auction process with false bid,
                information
                as it will put you at
                substantial personal legal risk. It is a crime to use a false Name,
                other false information or known invalid Credit Card to bid. The
                intending bidder who willfully entering into a erroneous or
                fictitious bids may result in prosecution by SDS</p>
            </div>
            <div class="image col-lg-4 order-1 order-lg-2" style='background-image: url("/img/invoice.png");'>
            </div>
          </div>

          <div style="text-align: left; margin-top: 30px;">
            <div style="background: #009ab3; width: fit-content; border-radius: 5px; padding: 2px 10px;">
              <h4 style="width: fit-content;">Broking</h4>
            </div>
          </div>



          <div class="row content" style="text-align: left;">
            <div class="col-lg-12">
              <p>
                The Company is providing the registered platform who wants to auction and who
                wants to bid the cereals product may allowed at the certain terms and conditions
                and with an agreement with the sellers and buyers.
              </p>
            </div>
          </div>

          <div style="text-align: left; margin-top: 30px;">
            <div style="background: #009ab3; width: fit-content; border-radius: 5px; padding: 2px 10px;">
              <h4 style="width: fit-content;">Distribution</h4>
            </div>
          </div>

          <div class="row content" style="text-align: left;">
            <div class="col-lg-12">
              <p>
                The Company is providing the registered platform who wants to auction and who
                wants to bid the cereals product may allowed at the certain terms and conditions
                and with an agreement with the sellers and buyers.
              </p>
            </div>
          </div>

          <div style="text-align: left; margin-top: 30px;">
            <div style="background: #009ab3; width: fit-content; border-radius: 5px; padding: 2px 10px;">
              <h4 style="width: fit-content;">Trading</h4>
            </div>
          </div>

          <div class="row content" style="text-align: left;">
            <div class="col-lg-12">
              <p>
                The SDS while conducting an auction trade the agricultural product I.e. Pusa
                Basmati Rice and other verity of Basmati Rice. To value the opening price and to
                work actually on the e-auction declared price which completely depends on the
                e-auction declared price and quantity of the product. The trading of Basmati Rice
                actually helps the agriculturist or Rice Holders to sale the rice at online e-auction.
                The trading also gives the scope to bidder while actual auction process either at
                higher side or at reverse side.
              </p>
            </div>
            <div class="row content">
              <div class="image col-lg-6">
                <img src="/img/professional.jpg" class="img-fluid image-center" alt="">
              </div>
              <div class="col-lg-6">
                <h4>i. Discount Offer</h4>
                <p>The SDS respects all the bidders and undertakes to provide
                  best possible services to the bidders. The SDS provides an
                  opportunity not only to the successful bidders but also a fair
                  chance to purchase the quality rice at the discounted rate
                  upon producing the participation receipt at the e-auction
                  center.
                </p>
              </div>
            </div>
          </div>

          <div class="row" style="margin-top: 30px;">
            <div class="col-lg-6">
              <img src="/img/admin/1.png" class="img-fluid" alt="">
            </div>
            <div class="col-lg-6">
              <h4>ii. E-Auction Process</h4>
              <p>The Registration Process
                for participation in e-auction in the official
                website of
                https://dealer.sdstrading.live
                g.live/login
                The SDS only offers e-auction of Basmati Rice. The bidder/s or Seller/s who desire to
                sale and to purchase/bid the basmati rice the Company has kept the auction amount in
                percentage for smooth functioning of e-auction process. The company has prepared the
                lots of Rice i.e. of 70 KG, 140 KG, 180 KG and 350 KG. These lot/s will be available for e-auction. The
                name of
                Basmati Rice will be displayed at the website. The opening
                aggregate price for each lot will be displayed at the below of the product and so also the
                declared price shall be displayed at the above side of the product is shown at the website.
                The auction process
                starts with registration
                of bidders or intending
                bidders by online mode
                and upon payment of
                participation charges.
              </p>
            </div>
          </div>
          <div class="row" style="margin-top: 30px;">
            <div class="col-lg-6">
              <p>
                The SDS offer the rice holders to bring there rice for auction at e-auction platform for earning the
                profits. The
                SDS also buy / trade
                in Basmati Rice therefore also having quantity of Basmati Rice at
                their own stock. <b>The auction process
                  starts with registration
                  of bidders or intending
                  bidders by online mode
                  and upon payment of
                  participation charges. </b>
                After entering and selecting the
                desire product the bidders is requires
                to select the percentage which are in
                the higher and reverse mode of the
                opening price.
                The following information is also important for e-auction process i. Auction
                Opening Date and Time ii. Auction Declared Date and Time iii. Name
                Agricultural Product iv. Lot which the bidder is opted for v. Bidding Amount
                vi. Bidding History vii. Last Bid or Basic Price etc. etc.
              </p>
              <img src="/img/admin/3.jpg" class="img-fluid image-center" alt="">
            </div>
            <div class="col-lg-6">
              <img src="/img/admin/2.jpg" class="img-fluid" alt="">
            </div>
          </div>

          <div class="row" style="margin-top: 30px;">
            <div class="col-lg-6">
              <h4>iii. Mode of E Auction</h4>
              <p>
                The SDS is conducting an e-auction once at a
                time through-out India. Therefore the prices
                may goes up or may goes down (Reverse) from
                the opening price therefore the company has kept an e-auction of reverse mode in e-auction which is
                completely new mode of e-auction for the Indian public.
              </p>
              <img src="/img/admin/3.jpg" class="img-fluid image-center" alt="">
            </div>
            <div class="col-lg-6">
              <h4>iv. Time of E Auction</h4>
              <p>The company is require to conduct many
                auction of Basmati Rice therefore the company
                fixed the time limit for deceleration of
                e-auction. Every bidder is require to
                participate within the time prescribe for e-auction process once the e-auction is
                declare for the said auction the bidder can not be participated and can not claim any
                right of bidding once auction time is out.
              </p>
              <img src="/img/admin/3.jpg" class="img-fluid image-center" alt="">
            </div>
          </div>
          <div class="row" style="margin-top: 30px;">
            <div class="col-lg-12">
              <h4>v. Buying & Bidding</h4>
              <p>To accept the Basmati Rice delivery after successful declaration of bid which is opted
                by the bidder during the e-auction process. The person who has successfully bids
                called as purchaser. The person who has owned the Basmati Rice in the e-auction
                process if not possible for him to take the delivery, the Company for their bidders has
                policy of Buy-Back Commitment to protect the interest of bidder. In the Buy-Back
                Commitment the Company immediately purchase and place said lot in the e-auction
                platform and may sale it immediately after meeting an application by the bidder for
                selling the rice to the Company, the Company may sale it in increased price rate and
                the bidder shall not have any claim on such item. The amount of payment after the
                purchase commitment will be paid immediately at the e-auction center where the
                bidder/s have applied for the auction. All payment will be made in Indian Rupees only.
              </p>
            </div>
          </div>
          <div class="row" style="margin-top: 30px;">
            <h4>vi. E-Auction History</h4>
            <div class="col-lg-4"></div>
            <div class="col-lg-4">
              <img src="/img/admin/4.png" class="img-fluid" alt="">
            </div>
            <div class="col-lg-4"></div>
            <div class="col-lg-12">
              <p>The SDS company maintain the history
                of the auction of last 2 days of the e-auction conducted by the company on
                the e-auction platform the history
                helps to the company to maintain
                record for the purpose of fixation of
                reserve price.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section><!-- End Services Section -->

    <!-- ======= Cta Section ======= -->
    <!-- <section id="cta" class="cta">
          <div class="container">

            <div class="row">
              <div class="col-lg-9 text-center text-lg-start">
                <h3>Our Mission</h3>
                <p> Is to deliver the excellent services to our Auctioneer and Bidders at E-Auction Platform.</p>
                <p> to Increase the economical growth of the farmers and to give the recognition
                  of the agricultural products, because we value the efforts of our Farmers,
                  Auctioneers and Bidders.</p>
              </div>
              <div class="col-lg-3 cta-btn-container text-center">
                <a class="cta-btn align-middle" href="#">Call To Action</a>
              </div>
            </div>

          </div>
        </section> -->
    <!-- End Cta Section -->

    <!-- ======= Features Section ======= -->
    <section id="features" class="features">
      <div class="container">
        <div class="section-title">
          <h2>Goals</h2>
        </div>
        <div class="row">
          <div class="col-lg-6 order-2 order-lg-1">
            <div class="icon-box mt-5 mt-lg-0">
              <i class="bx bx-receipt"></i>
              <h4>1.</h4>
              <p>The Basmati Rice generally used by higher or middle class persons, the company’s
                prime object is to provide the Basmati Rice at the door of every citizen at
                affordable price.
              </p>
            </div>
            <div class="icon-box mt-5">
              <i class="bx bx-cube-alt"></i>
              <h4>2.</h4>
              <p>The company is accepting the agriculturalist offer with the promise to supply his
                product after successful bids to the company for delivery purposes.</p>
            </div>
            <div class="icon-box mt-5">
              <i class="bx bx-images"></i>
              <h4>3.</h4>
              <p>Due to e-auction the price of travelling with goods in agricultural product market
                is not necessary to transport and further the agriculturalist is not require bear the
                APMC charges therefore the landlord of the product gets the actual price for which
                the Basmati Rice is sold in the open e-auction platform. </p>
            </div>
            <div class="icon-box mt-5">
              <i class="bx bx-shield"></i>
              <h4>4.</h4>
              <p>The Object of the company is to open the universal e-platform and to bring the
                Basmati Rice for e-auction at large market level to provide the minimum cost and
                maximum price to the seller by providing the e-platform.
              </p>
            </div>
          </div>
          <div class="image col-lg-6 order-1 order-lg-2" style='background-image: url("/img/features.jpg");'>
          </div>
        </div>
      </div>
    </section><!-- End Features Section -->

    <!-- ======= Clients Section ======= -->
    <!-- <section id="clients" class="clients">
          <div class="container">

            <div class="row no-gutters clients-wrap clearfix wow fadeInUp">

              <div class="col-lg-3 col-md-4 col-xs-6">
                <div class="client-logo">
                  <img src="/img/clients/client-1.png" class="img-fluid" alt="">
                </div>
              </div>

              <div class="col-lg-3 col-md-4 col-xs-6">
                <div class="client-logo">
                  <img src="" class="img-fluid" alt="">
                </div>
              </div>

              <div class="col-lg-3 col-md-4 col-xs-6">
                <div class="client-logo">
                  <img src="" class="img-fluid" alt="">
                </div>
              </div>

              <div class="col-lg-3 col-md-4 col-xs-6">
                <div class="client-logo">
                  <img src="" class="img-fluid" alt="">
                </div>
              </div>

              <div class="col-lg-3 col-md-4 col-xs-6">
                <div class="client-logo">
                  <img src="" class="img-fluid" alt="">
                </div>
              </div>

              <div class="col-lg-3 col-md-4 col-xs-6">
                <div class="client-logo">
                  <img src="" class="img-fluid" alt="">
                </div>
              </div>

              <div class="col-lg-3 col-md-4 col-xs-6">
                <div class="client-logo">
                  <img src="" class="img-fluid" alt="">
                </div>
              </div>

              <div class="col-lg-3 col-md-4 col-xs-6">
                <div class="client-logo">
                  <img src="" class="img-fluid" alt="">
                </div>
              </div>

            </div>

          </div>
        </section> -->
    <!-- End Clients Section -->

    <!-- ======= Counts Section ======= -->
    <!-- <section id="counts" class="counts">
          <div class="container">

            <div class="text-center title">
              <h3>What we have achieved so far</h3>
              <p>Iusto et labore modi qui sapiente xpedita tempora et aut non ipsum consequatur illo.</p>
            </div>

            <div class="row counters position-relative">

              <div class="col-lg-3 col-6 text-center">
                <span data-purecounter-start="0" data-purecounter-end="232" data-purecounter-duration="1"
                  class="purecounter"></span>
                <p>Clients</p>
              </div>

              <div class="col-lg-3 col-6 text-center">
                <span data-purecounter-start="0" data-purecounter-end="521" data-purecounter-duration="1"
                  class="purecounter"></span>
                <p>Projects</p>
              </div>

              <div class="col-lg-3 col-6 text-center">
                <span data-purecounter-start="0" data-purecounter-end="1463" data-purecounter-duration="1"
                  class="purecounter"></span>
                <p>Hours Of Support</p>
              </div>

              <div class="col-lg-3 col-6 text-center">
                <span data-purecounter-start="0" data-purecounter-end="15" data-purecounter-duration="1"
                  class="purecounter"></span>
                <p>Hard Workers</p>
              </div>

            </div>

          </div>
        </section> -->
    <!-- End Counts Section -->



    <!-- ======= Features Section ======= -->
    <section id="team" class="features">
      <div class="container">
        <div class="section-title">
          <h2>Team</h2>
        </div>
        <div class="row">
          <div class="image col-lg-4 order-2 order-lg-1" style='background-image: url("/img/team.jpeg");'></div>
          <div class="col-lg-8  order-1 order-lg-2">
            <div class="icon-box mt-5 mt-lg-0">
              <!-- <i class="bx bx-receipt"></i> -->
              <h4>Mr. Sudhir Dhananjay Sarode</h4>
              <h4>(Managing Director)</h4>

              <p>As we embark on an exciting new chapter in our
                corporate life, I have garbed this opportunity to serve and
                to contribute to reach at every citizen / person. The real
                intention to come forward with an idea of e-auction it s
                to bring changes in the farmers life and to provide the
                real consideration of their own product which is
                cultivated by them by putting hard work and invested the
                huge amount.
                Mr. Satish D. Jadhav
                (Business Developer)
                My main objective is to develop growth strategies
                focusing on both financial benefits and customer
                satisfaction. We are promoting the company's
                Basmati Rice online e-auction platform
                internationally.
                “Make no little plans, Make big plans; aim high
                in hope and work, remembering that a noble,
                logical diagram once recorded will never die.”
                Mr. Mahesh B. Gosai
                (Business Developer)
                I works with the digital team to create and
                manage digital world that exceed client
                expectations. By combining creative marketing
                and data insights, he works to generate the best
                results for his clients. </p>
              <p>
                It is my pleasure if I pays some contribution to the growth of the farmers and helps to
                change in lifestyle of every person who is wanted to change himself. I have established the
                said Company to fulfill the dream of all persons those who wants to grow, to come up and
                to change himself in the digital era. It is my endeavour to stop or to prevent the farmers
                exploitation and keep away all such persons who takes away the all crops / cereals of the
                farmers which they have cultivated with the hard work.</p>
              <p>
                I will be happy if I have given an opportunity to serve I will take all responsibility to
                progress yourself. The SDS is a family which always provides an opportunity to serve to all
                the person/s sincerely. This is my social progressive attempt for development of society and
                to take the farmers market in the nation wide.
              </p>
            </div>
          </div>
        </div>
        <!-- Team 2 -->
        <div class="container" style="margin-top:80px;">
          <div class="row">
            <div class="image col-lg-3 order-1 order-lg-2" style='background-image: url("/img/team3.jpeg");'></div>
            <div class="col-lg-3  order-2 order-lg-1">
              <div class="icon-box mt-5 mt-lg-0">
                <!-- <i class="bx bx-receipt"></i> -->
                <h4>Ashrunath Bharat Ambekar</h4>
                <h4>(Marketing Partner SDS Agricultural Manddi Pvt. Ltd.))</h4>

                <p>“As as one person i cannot change the world, but i can change the world of one person”.
                  ‘The philosophy i believe also i am trying to implement it from childhood in my life.’
                  In a span of 25 years, Aavishkar Group, the brainchild of Mr. Ashrunath Ambekar has achieved
                  multipronged growth in varios domains and inspired many youngsters by molding their careers </p>
              </div>
            </div>
            <!-- Team 3 -->
            <div class="image col-lg-3 order-3 order-lg-4" style='background-image: url("/img/team1.jpeg");'></div>
            <div class="col-lg-3  order-3 order-lg-3">
              <div class="icon-box mt-5 mt-lg-0">
                <!-- <i class="bx bx-receipt"></i> -->
                <h4>Mr. Satish Jadhav</h4>
                <h4>(Business Developer)</h4>

                <p>My main objective is to develop growth strategies
                  focusing on both financial benefits and customer
                  satisfaction. We are promoting the company's
                  Basmati Rice online e-auction platform
                  internationally.</p>
                <p>
                  "Make no little plans, Make big plans; aim high
                  in hope and work, remembering that a noble,
                  logical diagram once recorded will never die.”
                </p>
              </div>
            </div>

          </div>
        </div>
      </div>
    </section><!-- End Features Section -->

    <!-- ======= certification Section ======= -->
    <section id="certification" class="certification">
      <div class="container">

        <div class="section-title">
          <h2>Certification</h2>
          <p>SDS Agricultural Manddi Pvt. Ltd. certified by various Certifications & Awards for most powerful
            platform for Auctioning &
            Trading the rice on online platform.</p>
        </div>


        <div class="row certification-container">

          <div class="col-lg-4 col-md-6 certification-item filter-app">
            <img src="/img/certification/certification1.png" class="img-fluid" alt="">
          </div>

          <div class="col-lg-4 col-md-6 certification-item filter-web">
            <img src="/img/certification/certification2.png" class="img-fluid" alt="">
          </div>

          <div class="col-lg-4 col-md-6 certification-item filter-app">
            <img src="/img/certification/certification3.png" class="img-fluid" alt="">
          </div>

        </div>
      </div>
    </section><!-- End certification Section -->

    <!-- ======= Contact Section ======= -->
    <section id="contact" class="contact">
      <div class="container">

        <div class="section-title">
          <h2>Contact</h2>
          <p>SDS AGRICULTURAL MANDDI PVT.LTD.</p>
        </div>
      </div>

      <div>
        <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3790.9823947101418!2d74.58933188007046!3d18.164724935777283!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc39f6859bc3ad7%3A0x680ab518f8a5c1e0!2sSDS%20AGRICULTURAL%20MANDDI%20PVT.%20LTD.!5e0!3m2!1sen!2sin!4v1714569269949!5m2!1sen!2sin" 
         style="border:0; width: 100%; height: 350px;" allowfullscreen loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"></iframe>
      </div>

      <div class="container">

        <div class="row mt-5">

          <div class="col-lg-4">
            <div class="info" style=" border-radius: 5px; padding: 17px;">
              <div class="address">
                <i class="ri-map-pin-line"></i>
                <b> Registered Office:</b>
                        <br/>
                        <p>
Shop No. 6, Pionner Complex, Old Post Office Road, Raramati - 413102.
<br/>
<br/>
<b>Correspondent Address:</b>
<br/>
Gat No. 236, Mhasobawadi, Tal, Indapur, Dist. Pune - 413133
</p>
              </div>

              <div class="email">
                <i class="ri-mail-line"></i>
                <h4>Email:</h4>
                <p><a href="mailto:sdsauction1@gmail.com">sdsauction1@gmail.com</a></p>
              </div>

              <div class="phone">
                <i class="ri-phone-line"></i>
                <h4>Call:</h4>
                <p>
                  <a href="tel:+919028399595">9028399595 / 9156845871</a>
                </p>
              </div>

            </div>

          </div>

          <div class="col-lg-8 mt-5 mt-lg-0" style="background-color: white; border-radius: 5px; padding: 17px;">

            <form class="php-email-form">
              <div class="row">
                <div class="col-md-6 form-group">
                  <input type="text" v-model="contact.name" name="name" class="form-control" id="name"
                    placeholder="Your Name">
                </div>
                <div class="col-md-6 form-group mt-3 mt-md-0">
                  <input type="text" v-model="contact.phone" @keypress="$helperService.allowOnlyNumericValue($event)"
                    class="form-control" name="phone" id="phone" placeholder="Your Phone">
                </div>
              </div>
              <div class="form-group mt-3">
                <input type="text" v-model="contact.subject" class="form-control" name="subject" id="subject"
                  placeholder="Subject">
              </div>
              <div class="form-group mt-3">
                <textarea class="form-control" v-model="contact.comment" name="message" rows="5"
                  placeholder="Message"></textarea>
              </div>
              <div class="text-center"><button type="button" id="contact-btn"
                  style="padding: 7px 24px;margin-top: 6px;background: #121550;border: 0px;border-radius: 5px;color: white;"
                  v-on:click="request">Send Message</button></div>
            </form>

          </div>

        </div>

      </div>
    </section><!-- End Contact Section -->
  </main><!-- End #main -->

  <!-- ======= Footer ======= -->
  <footer id="footer">
    <div class="container">
      <h3 style="margin: 0px !important;">SDS</h3>
      <a style="color: white;" target="_blank" href="/term-condition"><u>Terms & Conditions</u></a>
      <a style="color: white; margin: 0 25px;"  target="_blank" href="/privacy-policy"><u>Privacy Policy</u></a>
      <a style="color: white; margin-right:25px;" target="_blank" href="/return-policy"><u>Return Policy</u></a>
      <a style="color: white; margin-right:25px;" target="_blank" href="/shipping-delivery"><u>Shipping Delivery</u></a>
      <a style="color: white;" target="_blank" href="/faq"><u>FAQ</u></a>
      <div class="social-links" style="margin-top: 10px;">
        <a href="#" class="twitter"><i class="bx bxl-twitter"></i></a>
        <a href="#" class="facebook"><i class="bx bxl-facebook"></i></a>
        <a href="#" class="instagram"><i class="bx bxl-instagram"></i></a>
        <a href="#" class="google-plus"><i class="bx bxl-skype"></i></a>
        <a href="#" class="linkedin"><i class="bx bxl-linkedin"></i></a>
      </div>
      <div class="copyright">
        &copy; Copyright <strong><span>SDS Agricultural Manddi Pvt. Ltd.</span></strong>. All Rights Reserved
      </div>
      <div class="credits">
      </div>
    </div>
  </footer>
<!-- End Footer --></template>
<script>
import moment from "moment";

export default {
  components: {

  },
  name: "Home",
  computed: {
    timerCount() {
      var minutes = Math.floor(this.time / 60);
      return (
        this.numberFormat(minutes) +
        ":" +
        this.numberFormat(this.time - minutes * 60)
      );
    },
  },
  mounted() {
    let selectHeader = document.getElementById('header')
    if (selectHeader) {
      const headerScrolled = () => {
        if (window.scrollY > 100) {
          selectHeader.classList.add('header-scrolled')
        } else {
          selectHeader.classList.remove('header-scrolled')
        }
      }
      window.addEventListener('load', headerScrolled)
      document.addEventListener('scroll', headerScrolled)
    }

    this.getTime();
    var self = this;
    setInterval(() => {
      if (self.time > 0) {
        self.time--;
      } else {
        self.getTime();
      }
    }, 1000);
  },
  data() {
    return {
      contact: {},
      isLogin: false,
      auctions: [],
      time: 0,
      isCall: false,
      count: 0,
      previousTime: '',
      currentTime: '',
      currentDate: ''
    };
  },
  methods: {
    showLogin() {
      this.isLogin = !this.isLogin;
    },
    forceClose() {
      this.isLogin = false;
    },
    getCurrentAuction() {
      this.$api
        .getAPI({
          _action: "/auctions/web/live",
        })
        .then((res) => {
          for (var i in res) {
            res[i].is_checked = false;
          }

          this.auctions = res;
          if (res.length > 0) {
            this.currentTime = moment(res[0].start_time).format(
              "hh:mm a"
            );
            this.currentDate = moment(res[0].start_time).format(
              "DD/MM/YYYY"
            );
          }
          if (res.length > 0) {
            this.previousTime = moment(res[0].end_time).format(
              "hh:mm a"
            );
          }
        })
        .catch(() => { });
    }, numberFormat(n) {
      return n > 9 ? "" + n : "0" + n;
    },
    getTime() {
      if (!this.isCall) {
        this.isCall = true;
        var $this = this;
        if (this.count > 0) {
          setTimeout(() => {
            $this.apiCall();
          }, this.count * 1000);
        } else {
          this.apiCall();
        }
      }
    },
    apiCall() {
      this.count++;
      this.$api
        .getAPI({
          _action: "/auctions/time",
          _is_hide_error: true
        })
        .then((res) => {
          this.time = res.time;
          this.count = 0;
          this.isCall = false;
          this.getCurrentAuction();
        })
        .catch(() => {
          this.isCall = false;
        });
    },
    redirect(url) {
      window.open(url, "_blank")
    },
    buy(data) {
      this.$shared.setMapValue("product", data);
      this.$router.push("/buy");
    },
    request() {
      var message = "";

      if (!this.contact.name) {
        message = "Please enter Name";
      } else if (!this.contact.phone) {
        message = "Please enter Phone";
      } else if (!this.contact.subject) {
        message = "Please enter subject";
      } else if (!this.contact.comment) {
        message = "Please enter comment";
      } else if (this.contact.phone.length != 10) {
        message = "Please enter valid phone number";
      } else if (this.contact.comment.length < 10) {
        message = "Please enter valid comment";
      }



      if (message) {
        this.$notify({
          type: "error",
          text: message,
        });
        return;
      }



      this.$api
        .postAPI({
          _action: "/web/contact-us",
          _body: this.contact,
          _buttonId: 'contact-btn'
        })
        .then((res) => {
          this.$notify({
            type: "success",
            text: res.message,
          });
          this.contact = {}
        })
        .catch(() => { });
    }
  }
};
</script>
