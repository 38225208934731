<template>
    <section id="privacy" class="services" style="padding-bottom: 0px;">
        <div class="container" style="height: 78vh;">

            <div class="section-title">
                <h2>Shipping & Delivery Policy</h2>
                <div style="text-align: left;">
                    <p>
                       <b>Last updated on 07-05-2024 18:35:12</b>
                    </p>
                    <br />
                    <p>
                        For International buyers, orders are shipped and delivered through registered international courier 
companies and/or International speed post only. For domestic buyers, orders are shipped through 
registered domestic courier companies and /or speed post only. Orders are shipped within 8+ Days days 
or as per the delivery date agreed at the time of order confirmation and delivering of the shipment subject 
to Courier Company / post office norms.
                    </p>
                    <br />
                    <p>SDS AGRICULTURAL MANDDI PRIVATE LIMITED is not liable for any delay in delivery by the 
courier company / postal authorities and only guarantees to hand over the consignment to the courier 
company or postal authorities within 8+ Days days from the date of the order and payment or as per the 
delivery date agreed at the time of order confirmation. Delivery of all orders will be to the address 
provided by the buyer. Delivery of our services will be confirmed on your mail ID as specified during 
registration. For any issues in utilizing our services you may contact our helpdesk on 9156919595 or 
sudhirrsarode9@gmail.com.</p>
                   
                </div>
            </div>
        </div>

        <footer id="footer">
            <div class="container">
                <div class="copyright">
                    &copy; Copyright <strong><span>SDS Agricultural Manddi Pvt. Ltd.</span></strong>. All
                    Rights Reserved
                </div>
                <div class="credits">
                </div>
            </div>
        </footer>
</section>
</template>
<script>

export default {
    components: {

    },
    name: "ShippingDelivery",
    computed: {

    },
    mounted() {

    },
    data() {
        return {
        };
    },
    methods: {

    }
};
</script>
