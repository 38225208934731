import { createWebHistory, createRouter } from "vue-router";
import Home from "@/views/Home.vue";
import TermCondition from "@/views/TermCondition.vue";
import ReturnPolicy from "@/views/ReturnPolicy.vue";
import PrivacyPolicy from "@/views/PrivacyPolicy.vue";
import TvProduct from '@/views/TVProduct.vue';
import Faq from "@/views/Faq.vue";
import Buy from "@/views/Buy.vue";
import ShippingDelivery from '@/views/ShippingDelivery.vue'
const routes = [
  {
    path: "",
    redirect: Home,
  },
  {
    path: "/home",
    name: "Home",
    component: Home,
  },
  {
    path: "/buy",
    name: "Buy",
    component: Buy,
  },
  {
    path: "/faq",
    name: "FAQ",
    component: Faq,
  },
  {
    path: "/term-condition",
    name: "Term Condition",
    component: TermCondition,
  },
  {
    path: "/return-policy",
    name: "Return Policy",
    component: ReturnPolicy,
  },
  {
    path: "/privacy-policy",
    name: "Privacy Policy",
    component: PrivacyPolicy,
  },
  {
    path: "/product",
    name: "Live Chart",
    component: TvProduct,
  },
  {
    path: "/shipping-delivery",
    name: "Shipping Delivery",
    component: ShippingDelivery,
  },
  
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
