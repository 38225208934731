import { defineRule } from 'vee-validate';

defineRule('required', (value, [name]) => {
    if (!value || value.length <= 0) {
        if (name) {
            return `Please enter ${name}`;
        }
        return 'This field is required';
    }
    return true;
});

defineRule('email', value => {
    if (!/^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,3}$/.test(value)) {
        return 'Please enter valid email';
    }
    return true;
});

defineRule('phone', value => {
    if (!/^[0-9]{10}$/.test(value)) {
        return 'Please enter valid contact number';
    }
    return true;
});

defineRule('minLength', (value, [limit]) => {
    if (value.length < limit) {
        return `This field must be at least ${limit} characters`;
    }
    return true;
});

defineRule('confirmed', (value, [target]) => {
    if (value === target) {
        return true;
    }
    return 'Passwords must match';
});