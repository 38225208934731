<template>
    <section id="privacy" class="services" style="padding-bottom: 0px;">
        <div class="container" style="height: 84vh;">

            <div class="section-title">
                <h2>Refund and Cancellation Policy</h2>
                <div style="text-align: left;">
                    <p>Our focus is complete customer satisfaction. In the event, if you received delivery of the product
                        damaged, we will refund back the money, provided the reasons are genuine and proved after
                        investigation. Please read the fine prints of each deal before buying it, it provides all the
                        details about the services or the product you purchase.</p>
                    <br />
                    <p>
                        In case of dissatisfaction from our services, clients have the liberty to cancel their Delivery and
                        request a refund from us. Our Policy for the cancellation and refund will be as follows:
                    </p>
                    <br />
                    <b>Cancellation Policy</b>
                    <p>
                        Cancellation is allowed only if product is damaged, for Cancellations please contact us via contact
                        number: 9028399595 / 9156845871
                        <br />
                        Request should be placed at the time of taking delivery.
                        <br />
                    </p>
                    <br />
                    <b>Refund Policy</b>

                    <p>
                        We will try our best to create the suitable design concepts for our clients.
                        <br />

                        In case any client is not completely satisfied with our products we can provide a refund.
                        <br />
                        Refunds will be issued to the original payment mode provided at the time of purchase and in case of
                        payment gateway name payments refund will be made to the same account.

                    </p>
                </div>
            </div>
        </div>

        <footer id="footer">
            <div class="container">
                <div class="copyright">
                    &copy; Copyright <strong><span>SDS Agricultural Manddi Pvt. Ltd.</span></strong>. All
                    Rights Reserved
                </div>
                <div class="credits">
                </div>
            </div>
        </footer>
</section>
</template>
<script>

export default {
    components: {

    },
    name: "ReturnPolicy",
    computed: {

    },
    mounted() {

    },
    data() {
        return {
        };
    },
    methods: {

    }
};
</script>
