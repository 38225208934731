class HelperService {

    constructor() {}


    sorting(sortBy, id) {
        var orderBy = "ASC";
        var className = "sorting_asc";
        var temp = document.getElementById(id)
        if (temp.className.includes('sorting_asc')) {
            className = 'sorting_desc'
        }
        if (className == "sorting_asc") {
            temp.classList.add('sorting_asc')
            temp.classList.remove('sorting_desc')
            orderBy = "ASC";
        } else if (className == "sorting_desc") {
            temp.classList.add('sorting_desc')
            temp.classList.remove('sorting_asc')
            orderBy = "DESC";
        }
        var obj = {
            order_by: orderBy,
            sort_by: sortBy
        }
        return obj;
    }

    allowOnlyNumericValue(e) {
        var numbers = /^[0-9]$/;
        if (!e.key.match(numbers) && e.keyCode != 8) {
            e.preventDefault();
            return false;
        }
    }

    checkMinAndMax(e) {
        let keyCode = (e.keyCode ? e.keyCode : e.which);
        if ((keyCode < 48 || keyCode > 57) && (keyCode !== 46 || e.target.value.indexOf('.') != -1)) {
            e.preventDefault();
        }

        // restrict to 2 decimal places
        if (e.target.value != null && e.target.value.indexOf(".") > -1 && (e.target.value.split('.')[1].length > 1)) {
            e.preventDefault();
        }


        if (e.target.value && parseFloat(e.target.value + e.key) > 100.00) {
            e.preventDefault();
            return false;
        }
    }

    allowDecimalValue(e) {
        let keyCode = (e.keyCode ? e.keyCode : e.which);
        if ((keyCode < 48 || keyCode > 57) && (keyCode !== 46 || e.target.value.indexOf('.') != -1)) {
            e.preventDefault();
        }

        // restrict to 2 decimal places
        if (e.target.value != null && e.target.value.indexOf(".") > -1 && (e.target.value.split('.')[1].length > 1)) {
            e.preventDefault();
        }
    }
    allowCharater(e) {
        if (
            (e.charCode > 64 && e.charCode < 91) ||
            (e.charCode > 96 && e.charCode < 123) ||
            e.charCode == 32
        ) {
            return true;
        } else {
            e.preventDefault();
            return false;
        }
    }
    allowNumber(e) {
        if (!/\d/.test(e.key) && e.key !== '.') {
            return e.preventDefault();
        }
    }
    getTitleCase(value) {
        if (!value) {
            return ""
        } else {
            var str = ''
            var arr = value.split('_')
            for (var i in arr) {
                if (i == 0) {
                    str = arr[i].charAt(0).toUpperCase() + arr[i].substring(1).toLowerCase();
                } else {
                    str += ' ' + arr[i].charAt(0).toUpperCase() + arr[i].substring(1).toLowerCase();
                }
            }
            return str
        }
    }


}

export default new HelperService({})