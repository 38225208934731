<template>
    <div>
        <section id="contact" class="contact">
            <div class="container">

                <div class="section-title">
                    <h2>Order Detail For {{auction.product_name}}</h2>

                </div>
            </div>


            <div class="container">

                <div class="row mt-5">

                    <div class="col-lg-4">
                        <div class="info" style=" border-radius: 5px; padding: 17px;">
                            <div class="row mt-5">
                                <img :src="auction.image" width="160" />
                                <br />
                                <span style="color:red;font-size:17px; font-weight:bold"><s>MRP {{auction.open_price +
                                1000}}</s></span>
                                <br />
                                <span style="color:green;font-size:17px; font-weight:bold">Buy at Rs
                                    {{auction.open_price}}</span>
                                <br />
                                <span style="color:black;font-size:14px;">Shipping Extra</span>
                                <br />
                                <input type="text" @keypress="$helperService.allowOnlyNumericValue($event)"
                                    name="quantity" class="form-control" id="quantity" v-model="auction.quantity"
                                    placeholder="Enter Quantity">
                            </div>

                        </div>

                    </div>

                    <div class="col-lg-8 mt-5 mt-lg-0"
                        style="background-color: white; border-radius: 5px; padding: 17px;">

                        <form class="php-email-form">
                            <div class="row">
                                <div class="col-md-6 form-group">
                                    <input type="text" name="name" v-model="auction.name" class="form-control" id="name"
                                        placeholder="Your Name">
                                </div>
                                <div class="col-md-6 form-group mt-3 mt-md-0">
                                    <input type="text" class="form-control"
                                        @keypress="$helperService.allowOnlyNumericValue($event)" v-model="auction.phone"
                                        name="phone" id="phone" placeholder="Your Phone">
                                </div>
                            </div>
                            <div class="form-group mt-3">
                                <textarea class="form-control" name="address" v-model="auction.address1" rows="5"
                                    placeholder="Enteer Address"></textarea>
                            </div>
                            <div class="row mt-3">
                                <div class="col-md-6  form-group">
                                    <input type="text" class="form-control" v-model="auction.city" name="city" id="city"
                                        placeholder="Enter City">
                                </div>
                                <div class="col-md-6  form-group">
                                    <input type="text" class="form-control" v-model="auction.zipcode" name="zipcode"
                                        id="zipcode" placeholder="Enter Zipcode">
                                </div>
                            </div>

                            <div class="text-center"><button type="button" id="place-oorder"
                                    style="padding: 7px 24px;margin-top: 6px;background: #121550;border: 0px;border-radius: 5px;color: white;"
                                    v-on:click="placeOrder">Place Order</button></div>
                        </form>

                    </div>

                </div>

            </div>
        </section>
    </div>
</template>
<script>

export default {
    components: {

    },
    name: "Buy",
    computed: {

    },
    mounted() {
        this.auction = this.$shared.getMapValue("product");
        if (this.auction && this.auction.product_id) {
            this.auction.quantity = 1;
        } else {
            this.$router.go(-1);
        }
    },
    data() {
        return {
            auction: {}
        };
    },
    methods: {

        placeOrder() {
            var message = "";

            if (!this.auction.quantity) {
                message = "Please enter Quantity";
            } else if (!this.auction.name) {
                message = "Please enter Name";
            } else if (!this.auction.phone) {
                message = "Please enter Phone";
            } else if (!this.auction.address1) {
                message = "Please enter address";
            } else if (!this.auction.city) {
                message = "Please enter city";
            } else if (!this.auction.zipcode) {
                message = "Please enter zipcode";
            } else if (this.auction.phone.length != 10) {
                message = "Please enter valid phone number";
            } else if (this.auction.address1.length < 10) {
                message = "Please enter valid address";
            }



            if (message) {
                this.$notify({
                    type: "error",
                    text: message,
                });
                return;
            }

            this.auction.address = this.auction.address1 + " " + this.auction.city + " " + this.auction.zipcode;


            this.$api
                .postAPI({
                    _action: "/web/order",
                    _body: this.auction,
                    _buttonId: 'place-oorder'
                })
                .then((res) => {
                    this.$notify({
                        type: "success",
                        text: res.message,
                    });
                    this.$router.go(-1);
                })
                .catch(() => { });
        }
    }
};
</script>
  