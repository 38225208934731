import axios from "axios";
import { notify } from "@kyvg/vue3-notification";

var http = null;

// var baseURL = "http://localhost:8092";

 var socketURL = 'wss://api.sdstrading.live:15673/ws';
var baseURL = "https://api.sdstrading.live";

// var socketURL = "wss://stagapi.sdstrading.live:15673/ws";
// var baseURL = "https://stagapi.sdstrading.live";

class WebService {
  constructor() {
    http = axios.create({
      baseURL: baseURL,
    });
  }

  getAPI({ _action, _body, _buttonId, _hideLoader, _is_hide_error }) {
    let params = new URLSearchParams();
    for (let key in _body) {
      params.set(key, _body[key]);
    }

    if (this.addLoader(_buttonId, _hideLoader)) {
      return;
    }

    return http
      .get(_action, {
        params: params,
        headers: this.getHeader(),
      })
      .then((response) => {
        this.removeLoader(_buttonId, _hideLoader);
        return response.data;
      })
      .catch((e) => {
        this.removeLoader(_buttonId, _hideLoader);
        return this._errorHandler(e, _is_hide_error);
      });
  }

  postAPI({ _action, _body, _buttonId, _hideLoader, _is_hide_error }) {
    if (this.addLoader(_buttonId, _hideLoader)) {
      return;
    }

    return http
      .post(_action, JSON.stringify(_body), { headers: this.getHeader() })
      .then((response) => {
        this.removeLoader(_buttonId, _hideLoader);
        return response.data;
      })
      .catch((e) => {
        this.removeLoader(_buttonId, _hideLoader);
        return this._errorHandler(e, _is_hide_error);
      });
  }

  putAPI({ _action, _body, _buttonId, _hideLoader }) {
    if (this.addLoader(_buttonId, _hideLoader)) {
      return;
    }
    return http
      .put(_action, JSON.stringify(_body), { headers: this.getHeader() })
      .then((response) => {
        this.removeLoader(_buttonId, _hideLoader);
        return response.data;
      })
      .catch((e) => {
        this.removeLoader(_buttonId, _hideLoader);
        return this._errorHandler(e);
      });
  }

  deleteAPI({ _action, _buttonId, _hideLoader }) {
    if (this.addLoader(_buttonId, _hideLoader)) {
      return;
    }
    return http
      .delete(_action, { headers: this.getHeader() })
      .then((response) => {
        this.removeLoader(_buttonId, _hideLoader);
        return response.data;
      })
      .catch((e) => {
        this.removeLoader(_buttonId, _hideLoader);
        return this._errorHandler(e);
      });
  }

  uploadImageAPI({ _action, _file, _key, _body, _buttonId, _hideLoader }) {
    if (!_key) {
      _key = "file";
    }
    if (this.addLoader(_buttonId, _hideLoader)) {
      return;
    }
    let formData = new FormData();
    formData.append(_key, _file);
    for (let key in _body) {
      formData.append(key, _body[key]);
    }

    return http
      .post(_action, formData, { headers: this.getBaseHeader() })
      .then((response) => {
        this.removeLoader(_buttonId, _hideLoader);
        return response.data;
      })
      .catch((e) => {
        this.removeLoader(_buttonId, _hideLoader);
        return this._errorHandler(e);
      });
  }

  _errorHandler(error, _is_hide_error) {
    if (error.response != null) {
      error = error.response;
    }
    var errorMessage;
    if (!error || !error.status) {
      errorMessage = "Server Not Responding";
    } else if (error.status === 401) {
      errorMessage =
        (error && error.data && error.data.message) || "Bad Response";
      this.logout();
    } else if (error.status === 500) {
      errorMessage = "Server Error";
    } else {
      errorMessage =
        (error && error.data && error.data.message) || "Bad Response";
    }
    // alert(error.data.message)
    if (!_is_hide_error) {
      notify({
        type: "error",
        text: errorMessage,
      });
    }
    throw new Error(errorMessage);
  }

  logout() {
    if (window.location.pathname != "/login") {
      localStorage.clear();
      window.location.href = "/login";
    }
  }

  getHeader() {
    var headers = this.getBaseHeader();
    headers["Content-Type"] = "application/json";
    return headers;
  }

  getBaseHeader() {
    var headers = {};
    if (localStorage.getItem("token")) {
      headers["Authorization"] = "Bearer " + localStorage.getItem("token");
    } else {
      headers["Authorization"] = "Basic ZGVhbGVyOnRyYWRpbmc=";
    }

    return headers;
  }

  addLoader(id) {
    if (id) {
      var button = document.getElementById(id);
      if (button.disabled) {
        return true;
      }

      button.disabled = true;
      var loader = document.createElement("i");
      loader.className = "fa fa-spinner fa-spin mr-2";
      button.prepend(loader);
      return false;
    }
  }

  removeLoader(id) {
    if (id) {
      var button = document.getElementById(id);
      button.disabled = false;
      button.removeChild(button.childNodes[0]);
    }
  }

  getBaseUrl() {
    return baseURL;
  }

  getSocketUrl() {
    return socketURL;
  }
}

export default new WebService();
