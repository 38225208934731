<template>
    <section id="privacy" class="services" style="padding-bottom: 0px;">
        <div class="container">

            <div class="section-title">
                <h2>Auction and Buy Back Commission Related Questions</h2>
                <div style="text-align: left;">
                    <b> What is the time period for Live Auction?</b>
                    <p>
                        Live Auction will continue by 24*7 on every 15 minutes.
                    </p>
                    <br />
                    <br />
                    <b>
                        What is the Promotion?</b>
                    <p>
                        SDS Rice Auction gives you opportunity to earn extra income by promoting
                        SDS Auction app. On each promotion SDS will gives you 1/- for app
                        promotion on invited Bidder’s 1st trade & reference commission also.</p> <br />
                    <br />
                    <b> How much % I will get on reference?</b>
                    <p>
                        SDS Rice Auction gives you 3% reference commission on trade of the bidder
                        referred by you.
                    </p> <br />
                    <br />
                    <b> What are the minimum invest amount for Auction?</b>
                    <p>
                        Investment depends upon Lot size company will charge Auction Entry Fee is
                        only 0.10 / KG (Ten Paise/ KG).
                    </p> <br />
                    <br />
                    <b>
                        How many Commission on per kg for successful bidder?
                    </b>
                    <p>
                        Company will give 1.82/KG (1 rupee 82 paise / KG) commission to the
                        successful bidder.
                    </p> <br />
                    <br />
                    <b>
                        What is View Graph?
                    </b>
                    <p>
                        Graph will show you trend of current trade status.
                    </p> <br />
                    <br />
                    <b>
                        What is View Chart?</b>
                    <p>
                        View Chart show you previous 7 days Auction History which are declared in
                        past 7 days.
                    </p> <br />
                    <br />
                    <b>
                        How to make Recharge of Entry Fee on my Account?
                    </b>
                    <p>
                        lease select Entry Fee Recharge option from Menu enter the amount press
                        on recharge button, select the mode of payment ex. Debit Card, Net Banking
                        or UPI (any UPI) and proceed for next process as per banking. You will get
                        massage on successful or unsuccessful transaction.

                    </p> <br />
                    <br />
                    <b>
                        Does the system save my trading & transaction History?
                    </b>
                    <p>
                        Yes, system saves user’s trading and Transaction history. Select my trade
                        history option for trading history and select transaction option then select
                        date range and transaction type then press search button. You will get total
                        transaction history.
                    </p> <br />
                    <br />
                    <b>
                        How can I withdraw Buy Back Commission?
                    </b>
                    <p>
                        In order to Withdraw Buy Back Commission from your trading account,
                        please press Refund Brokerage button from menu tag. Enter amount and
                        then click on submit button.

                    </p> <br />
                    <br />
                    <b>
                        How much time required for amount deposit in bank account after withdrawals submit?</b>

                    <p>
                        Our withdrawal facility is connected to “Auto Withdrawal System” so it will
                        instantly transfer amount in your bank account. (*fund transfer is depending
                        upon bank fund release policy)
                    </p> <br />
                    <br />
                    <b>
                        How to possible for me to take rice delivery?
                    </b>
                    <p>
                        Yes, you can take lot rice delivery by making full payment and taxes with
                        transportation charges.
                    </p> <br />
                    <br />
                    <b>
                        What is Discount Rice offer?</b>
                    <p>
                        SDS Rice Auction gives a facility to all bidders (Successful & Unsuccessful) to
                        get Basmati Rice at Discount rate. Bidder will get 80% of Auction entry fee as
                        a discount at the time of claim discount rice, Bidder should need to purchase
                        per lot 1 KG Rice for the benefit of Discount Rice offer.
                    </p> <br />
                    <br />
                    <b>
                        How can I take Discount offer rice delivery?
                    </b>
                    <p>
                        Bidder must order minimum 50 KG Basmati Rice to take discount offer rice
                        delivery by Transport (Shipping Charges Extra). For More Information
                        contact our customer care 9175229403 / 9172619403 / 9175319403
                    </p>

                </div>
            </div>
            <div class="col-12 row">
                <YouTube :width="width" style="margin-bottom: 10px; margin-right: 10px;" :style="isMobile ? 'widht' : ''"
                    v-if="isShow" src="https://www.youtube.com/watch?v=6ijOvAML7wI" ref="youtube" />
                <YouTube :width="width" style="margin-bottom: 10px;" v-if="isShow1"
                    src="https://www.youtube.com/watch?v=8AhnW2LEPn8" ref="youtube1" />
                <YouTube :width="width" style="margin-bottom: 10px; margin-right: 10px;" v-if="isShow2"
                    src="https://www.youtube.com/watch?v=a77wVrNwx7E" ref="youtube2" />
                <YouTube :width="width" style="margin-bottom: 10px;" v-if="isShow3"
                    src="https://www.youtube.com/watch?v=xRglVfAQqu4" ref="youtube3" />
            </div>
        </div>

        <footer id="footer">
            <div class="container">
                <div class="copyright">
                    &copy; Copyright <strong><span>SDS Agricultural Manddi Pvt. Ltd.</span></strong>. All Rights Reserved
                </div>
                <div class="credits">
                </div>
            </div>
        </footer>
    </section>
</template>
<script>
import YouTube from 'vue3-youtube'

export default {
    components: {
        YouTube
    },
    name: "Faq",
    computed: {

    },
    mounted() {
        if (window.outerWidth < 500) {
            this.width = window.outerWidth - 20;
        } else {
            this.width = (window.outerWidth / 3) - 20;
        }
        var $this = this;
        setTimeout(() => {
            $this.isShow = true;
        }, 500);
        setTimeout(() => {
            $this.isShow1 = true;
        }, 1000);
        setTimeout(() => {
            $this.isShow2 = true;
        }, 1500);
        setTimeout(() => {
            $this.isShow3 = true;
        }, 2000);
    },

    data() {
        return {
            isShow: false,
            isShow1: false,
            isShow2: false,
            isShow3: false,
            width: 200
        };
    },
    methods: {

    }
};
</script>
