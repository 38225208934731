<template>
    <section id="privacy" class="services" style="padding-bottom: 0px;">
        <div class="container">

            <div class="section-title">
                <h2>Terms & Conditions</h2>
                <div style="text-align: left;">
                    <p>
                        1. M/s SDS Agricultural Manddi Pvt. Ltd. “(Hereinafter
                        referred as SDS”). The participating parties or any individual person shall be called as
                        bidders. The successful Bidder shall be called as buyer/ purchaser or buyers/
                        purchasers. The object of the company is to conduct the online (live site auction) E-
                        auction of Basmati Rice. The Company is conducting the online (live site
                        auction)auction of Basmati Rice through their registered agent/dealers and sub-
                        dealers. The Company is the owners and stock of the various types of Basmati Rice
                        which is to be sold by online e-auction in the electronic market place or call upon the
                        stock holder to place his/her goods in live site auction.
                    </p>
                    <br />
                    <p>
                        2. The object of the company is to open unique and digital platform to the public at large
                        who wants to buy/ purchase the Basmati Rice at the minimum price or to provide the
                        profit over the purchased rice to the prospective purchase as per the marketing policy
                        of the Company if the successful bidder want to brought back the lot of rice in the live
                        site stock auction.
                    </p>
                    <br />
                    <p>
                        3. The general terms and conditions(herein after referred as GTC) are common for all
                        auction purchaser and in addition to these, the Special Terms and conditions (herein
                        after referred as STC) for the dealers and sub-dealers who shall be the integral part of
                        the said online e-auction process. The terms and conditions which are framed by the
                        company are unalterable and which cannot be modified at any cost. No such request
                        can be entertained by the company. In case any conflict arises in the GTC and STC the
                        decision of the SDS shall remain final and which cannot be disputed in any court of
                        law in any manner in whatsoever nature. Since, it is purely the company’s rights and
                        property.
                    </p>
                    <br />
                    <p>
                        4.1. The agent or dealer and sub dealer who wanted to be part of SDS and who is interested
                        in taking the dealership/ agency or sub-dealership/ sub agency from the SDS is required
                        to be registered with the online as well manually agency/ dealership form/agreement and
                        to fulfill the condition to become the agent/dealer can become successful agent of the
                        SDS to organize/ arrange the online (live site) auction with help of third party auction
                        purchaser.
                    </p>
                    <br />
                    <p>
                        4.2. The agent/ dealer who fulfils the conditions whose accounts will be activated and the
                        person who does not fulfills the conditions such person’s account will be deactivated.
                    </p>
                    <br />
                    <p>
                        4.3 The person/ agent/third party purchaser who is participated in the e-auction process after
                        the compliance of all the terms and conditions of e-auction the person is require to pay
                        the participation fees/charge by online mode either personally or through the dealer/
                        recognized agent of company. The person may participate as a third party through the
                        dealer or sub dealer or may registered himself for participation.
                    </p>
                    <br />
                    <p>
                        4.4 The participation fee paid by individual person after successful registration can be treated
                        as the auction bidding fees. The bidding fees for participation along with refundable
                        deposit including CGST and SGST taxes is require to be paid for every e-auction if he
                        wanted to participate more than one time in e-auction.
                    </p>
                    <br />
                    <p>
                        4.5. The participation charge will be 10 Paisa Per/ Kg which includes Refundable Deposit
                        (RDP) is 80 % and 20 % Brokerage which includes 18% GST.
                    </p>
                    <br />
                    <p>
                        4.6 The participant to qualify and to be identified as a successful bid the following things can
                        be taken into consideration which is as follows:-
                    </p>
                    <br />
                    <p>
                        &nbsp;&nbsp; a) Price: - The higher price bids qualify and supersedes the lower
                        Price bids.
                    </p>
                    <p>
                        &nbsp;&nbsp; b) Time:- Between the bids same price, the bid which was placed first will be
                        called
                        qualified bidder and shall be supersedes.
                    </p>
                    <p>
                        &nbsp;&nbsp; c) The decision of SDS in respect of success or otherwise of a bid shall be final
                        and
                        binding upon the bidder/ participants. The SDS reserve its right to reject the bid
                        without notice if the participant is not participated in good faith.
                    </p>
                    <p>
                        &nbsp;&nbsp; d) The SDS shall not be liable for any failure or delay in performance due to any
                        reason
                        and no complaint in this regard can be entertained.
                    </p>
                    <p>
                        &nbsp;&nbsp; e) All intending bidder who are participating directly in live E-auction will have
                        to pay
                        participation fees by online mode only. In case participants are added through dealer or
                        sub-dealer he may also deposit offline payment or online payment as per choice of the
                        participant/bidder with the dealer or sub-dealer.
                    </p>
                    <p>
                        &nbsp;&nbsp; d) All intending direct bidder after successful registration and payment, the
                        bidder/participant will be able to access the auction platform and no other person will
                        have access to the auction platform.
                    </p>
                    <p>
                        &nbsp;&nbsp; e) The auction notification mentioning the starting date, list of available Rice,
                        location of
                        e-auction.
                    </p>
                    <p>
                        &nbsp;&nbsp; f) The details of e-auction will be issued to the register bidder by print out or
                        will be sent
                        to his mobile number if requested by the bidder.
                    </p>
                    <p>
                        &nbsp;&nbsp; g) The bidder after selecting the desired rice for e-auction, all bidder can
                        finally arrive at
                        the (live site) online e-auction platform. The platform containing list of items, quantity
                        of items, price, upper bidding (upper circuit) and down bidding (lower circuit).
                    </p>
                    <p>
                        &nbsp;&nbsp; h) The Participant who has brought back his lot which is successfully bid/won the
                        stock/lot of Basmati Rice such participant refundable deposit (RDP) will be forfeited
                        immediately. The participant who has accepted the delivery his/her are RDP will be
                        adjusted in the total amount payable against successfully biding lot.
                    </p>
                    <p>
                        &nbsp;&nbsp; i) The opening price of day will be published at the online site of the company to
                        the same
                        day. The auction can be called upper and reverse mode the auction which is 50% less
                        of opening price can be called as lower circuit and minimum 50% up is called has
                        upper circuit.
                    </p>
                    <p>
                        &nbsp;&nbsp; j) The Company or stock holder who is the owner of the stock has every right to
                        decide the
                        opening price to bid for that day
                    </p>
                    <p>
                        &nbsp;&nbsp; k) Before the start of an live site auction buyer/bidder are allowed the check the
                        item on
                        sale and examine the same.
                    </p>
                    <p>
                        &nbsp;&nbsp; l) The pictures and description of the items on sale are displayed when the bidding
                        process
                        is officially opened.
                    </p>
                    <p>
                        &nbsp;&nbsp; m) The auction is being online live site auction no bids in advance is accepted and
                        bidder
                        are permitted to participate by nominal payment of brokerage has stated herein above.
                    </p>
                    <br />
                    <p>
                        4.7 The dealer acknowledged and agrees the contains wherever applicable, the contain
                        including but not limited to text, software, graphics, video or other material contain in
                        web site including advertisement or information is protected by copy right.
                    </p>
                    <br />
                    <p>
                        4.8 The refundable deposit (RDP) as clause (h of 4.6) will be treated as
                        Termination of entered amount of refundable deposit (RDP).
                    </p>
                    <br />
                    <p>
                        4.9 The process of auction for calling the bides in the mode of percentage of opening
                        price i.e. 1% and above and vice versa.
                    </p>
                    <br />
                    <p>
                        4.10. The Company is not responsible for incase any problem while making online payment
                        and same is not completed the company will not responsible for any such problem
                        may arise.
                    </p>
                    <br />
                    <p>
                        4.11. The payment gateway provider is third party service provider if any issue arises
                        regarding payment gateway service provider the person is require to complaint to
                        Company but to solve such problem at their level.
                    </p>
                    <br />
                    <p>
                        4.12 The quality of Rice may very product wise.
                    </p>
                    <br />
                    <p>
                        4.13.1 The Company does not offer any financial initiatives or
                        temptation to any person.
                    </p>
                    <br />
                    <p>
                        4.13.2 In case of incorrect use of Company’s payment gateway the company may charge
                        18% of service tax payment over 3% of payment of the payment gateway amount i.e
                        total 21% and Company may also block the account of such person.
                    </p>
                    <br />
                    <p> 4.13.3 The claim or validity of the receipts d+2 days for the date of auction after the expiry
                        of the same the Company will not entered any claim against the expired auction
                        receipt.
                    </p>
                    <br />
                    <p> 4.13.4. All the legal matters of the Company are subject to Baramati jurisdiction.
                    </p>
                    <br />
                    <p> 4.13.5 The company reserved his right to change, add, alter, remove., amend and correct
                        any of the condition any stage and no one can dispute about the right of the
                        Company.
                    </p>
                    <br />
                    <p> 4.13.6 The terms and condition will made available in the other languages at the request of
                        the party
                    </p>
                    <br />
                    <p>
                        5. The bidder is permitted to utilize all material information for personal use to participate
                        in the bidding process or online e auctions.
                    </p>
                    <br />
                    <p>
                        6. The online e-auction will be published at official web site of the company every after 5
                        or 15 minutes as per the bidding process.
                    </p>
                    <br />
                    <p>
                        6.1 The bidder/participant shall be personally liable for any loss caused if the participant is
                        not become a successful bidder.
                    </p>
                    <br />
                    <p>
                        6.2 The successful bidder shall be announced as purchaser of the Basmati Rice and he will
                        be asked whether the purchaser is interested in delivery of Basmati Rice is which is
                        successfully win/bid by the purchaser, is require to submit/provide postal address of the
                        said purchaser. In case the successful bidder is not interested to take the delivery of the
                        Basmati Rice the bidder have/has choice to resale it to the company with the fixed profit
                        margin of Rs.1.82/-(In Rupees One Rupee and Eighty Two Paise Only) the said
                        amount will be handed over to the said participant immediately.
                    </p>
                    <br />
                    <p>
                        6.3 The account of the individual participant which is created by person will be deactivated
                        after logging out or after completion of auction process and can be re-activated by
                        sending OTP and confirming the account details by the company. If the participant
                        wanted it to be re-use.
                    </p>
                    <br />
                    <p>
                        6.4 Any person has any difference in connection with online e-auction the participant/
                        bidder can write to company’s director or managing director or Chief Executive officer
                        or any other officer designated by the company for any question relating to e-auction.
                    </p>
                    <br />
                    <p>
                        6.5 The following information will be available on the auction platform:-
                    </p>
                    <p>
                        &nbsp;&nbsp; a) Auction Platform
                    </p>
                    <p>
                        &nbsp;&nbsp; b) Auction date </p>
                    <p>
                        &nbsp;&nbsp; c) Closing time </p>
                    <p>
                        &nbsp;&nbsp; d) Rice Variant Name </p>
                    <p>
                        &nbsp;&nbsp; e) Rice Quantity </p>
                    <p>
                        &nbsp;&nbsp; f) Last bid basic price </p>
                    <p>
                        &nbsp;&nbsp; g) Bid History </p>
                    <p>
                        &nbsp;&nbsp; h) Owner of Rice stock </p>
                    <br />
                    <p>

                        7) The bid once given cannot be retracted.
                        <br>
                        &nbsp;&nbsp; &#xbb; Bidding process can be completely online by live site either on official
                        website or
                        though mobile App or by visiting to the authorized centers .
                        <br>
                        &nbsp;&nbsp; &#xbb; Each bidder will have the option to declare his bid at maximum value in the
                        mode
                        of percentage i.e. 1% to10% on the fixed price of the commodity/product/goods is
                        restricted.
                        <br>
                        &nbsp;&nbsp; &#xbb; The bid value together with all taxes as applicable shall have to be paid by
                        the
                        successful bidder by demand draft/neft/by online/ credit or debit card or by
                        payment gateway.
                        <br>
                        &nbsp;&nbsp; &#xbb; The Sale values after successful bidding if the bidder chosen to accept the
                        Basmati
                        Rice. The invoice will be inclusive of all applicable Taxes. If the sale amount is
                        paid immediately the company shall cart the said rice for delivery purpose and
                        shall take the follow up till the time the item delivers and provide the track report
                        to the concern person.
                    </p>
                    <br />
                    <br />
                    <br />
                    <b> Disclaimer</b>
                    <p>
                        Auction participation involves financial risk and may take some getting used to. Consumers
                        should participate in SDS Baramati Rice Auction with their educated attitude and common
                        sense, the sole objective of SDS Baramati Rice Auction is to sell Basmati Rice through
                        auction at moderate prices. SDS does not offer any kind of financial inducement and will not
                        be responsible for your profit or loss.</p>
                </div>
            </div>
        </div>
        <footer id="footer">
            <div class="container">
                <div class="copyright">
                    &copy; Copyright <strong><span>SDS Agricultural Manddi Pvt. Ltd.</span></strong>. All
                    Rights Reserved
                </div>
                <div class="credits">
                </div>
            </div>
        </footer>
</section>
</template>
<script>

export default {
    components: {

    },
    name: "TermCondition",
    computed: {

    },
    mounted() {

    },
    data() {
        return {
        };
    },
    methods: {

    }
};
</script>
